import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
/** @jsx jsx */
import { Styled, jsx } from 'theme-ui'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Flex from '../components/Flex'
import Image from '../components/Image'

const propTypes = {
  data: PropTypes.object,
}
const defaultProps = {}

const Imprint = ({ data }) => (
  <Layout line>
    <SEO title="Impressum" />
    <Flex smallplus sx={{ flexDirection: `column` }}>
      <h1>Impressum</h1>
    </Flex>
    <Flex
      large
      sx={{
        p: 0,
        height: [`240px`, `320px`, `400px`, `400px`],
      }}
    >
      <Image fluid={data.imprint.childImageSharp.gatsbyImageData} />
    </Flex>
    <Flex sx={{ flexDirection: `column` }}>
      <h2>Angaben gem&auml;ß § 5 TMG:</h2>
      <address>
        Friederike E.L. Vilmar
        <br />
        Untermainkai 19
        <br />
        60329 Frankfurt am Main
        <br />
        Deutschland
      </address>
      <h2>Kontakt:</h2>
      <p>
        Telefon: +49 69 240 031 70-0
        <br />
        Telefax: +49 69 240 031 70-9
        <br />
        E-Mail: {` `}
        <Styled.a href="mailto:info@ra-vilmar.de" rel="noreferrer noopener">
          info@ra-vilmar.de
        </Styled.a>
      </p>
      <h2>Kooperation</h2>
      <p>
        In Kooperation mit
        <br />
        <br />
        JAN SMOLLICH & KOLLEGEN *<br />
        Rechtsanwälte | Fachanwälte | Mediatoren | Notar
        <br />
        Südergraben 47
        <br />
        24937 Flensburg
        <br />
        <Styled.a
          href="https://www.smollich-kollegen.de/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Website
        </Styled.a>
        <br />
        <br />* als selbst&auml;ndiger Rechtstr&auml;ger
      </p>
      <h2>Umsatzsteuer:</h2>
      <p>
        Umsatzsteuer-Identifikationsnummer gem&auml;ß §27 a Umsatzsteuergesetz:
        <br />
        DE326500259
      </p>
      <h2>Verantwortlich f&uuml;r den Inhalt nach § 55 Abs. 2 RStV:</h2>
      <address>
        Friederike E.L. Vilmar
        <br />
        Untermainkai 19
        <br />
        60329 Frankfurt am Main
        <br />
        Deutschland
      </address>

      <h2>Streitschlichtung</h2>
      <p>
        Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)
        bereit:{' '}
        <Styled.a
          href="https://ec.europa.eu/consumers/odr"
          target="_blank"
          rel="noreferrer noopener"
        >
          Online-Streitbelegung
        </Styled.a>
        <br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teilzunehmen.
      </p>
      <h3>Haftung f&uuml;r Inhalte</h3>
      <p>
        Als Diensteanbieter sind wir gem&auml;ß § 7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen
        Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
        Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde
        Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine
        rechtswidrige T&auml;tigkeit hinweisen.
      </p>
      <p>
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
        allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist
        jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei
        Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
        entfernen.
      </p>
      <h3>Haftung f&uuml;r Links</h3>
      <p>
        Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren Inhalte wir keinen
        Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine
        Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist stets der
        jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden
        zum Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;ße &uuml;berpr&uuml;ft.
        Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
      </p>
      <p>
        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
        Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      </p>
      <h3>Urheberrecht</h3>
      <p>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
        deutschen Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der
        Verwertung außerhalb der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen
        Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
        f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet.
      </p>
      <p>
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
        Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
        gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
        bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
        wir derartige Inhalte umgehend entfernen.
      </p>
    </Flex>
  </Layout>
)

Imprint.propTypes = propTypes
Imprint.defaultProps = defaultProps

export default Imprint

export const query = graphql`
  {
    imprint: file(relativePath: { eq: "rav/csaba-pap-Wx5a5D3sV3Q-unsplash.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, placeholder: TRACED_SVG, layout: CONSTRAINED)
      }
    }
  }
`
